import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { CONFIGURATION_CONSTANT, NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { InputType, UnitType } from 'src/app/enums';
import { Configuration } from 'src/app/models';
import { DataService, OrganizationService, ToasterService, UserService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { LinkDeviceModalComponent } from '../link-device-modal/link-device-modal.component';
import { LinkUserModalComponent } from '../link-user-modal/link-user-modal.component';

@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrls: ['./configuration-modal.component.scss']
})
export class ConfigurationModalComponent implements OnInit {

  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  inputType = InputType;
  unitType = UnitType;
  configurationForm !: FormGroup;
  confirmButtonLabel: string = '';
  organization: any;

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  linkDeviceComponent!: MdbModalRef<LinkDeviceModalComponent>;
  linkUserComponent!: MdbModalRef<LinkUserModalComponent>;

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private nightWebUtil: NightwebUtil,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private organizationService: OrganizationService,
    public modalRef: MdbModalRef<ConfigurationModalComponent>
  ) {
  }

  get configurations(): FormArray {
    return this.configurationForm.get('configurations') as FormArray;
  }

  ngOnInit(): void {
    this.setConfigurationForm();
  }

  setConfigurationForm() {
    const { deviceId, userId, organizationId, mode, module } = this.formData;
    this.configurationForm = this.formBuilder.group({
      deviceId: [deviceId, Validators.required],
      endUserId: [userId, Validators.required],
      configurations: this.formBuilder.array([])
    });
    if (module === 'user') {
      this.loading = true;
      this.userService.getUserById(this.formData.userId)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response) => {
            this.formData.configurations = response.data.configurations;
            this.setConfigurations();
          },
          error: (e) => {
          }
        });
    } else if (module === 'organization') {
      this.loading = true;
      this.organizationService.getOrganizationById(this.formData.organizationId)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response) => {
            this.organization = response.data;
            this.formData.configurations = response.data.configurations;
            this.setConfigurations();
          },
          error: (e) => {
          }
        });
    }
  }

  setConfigurations() {
    const configurations = this.nightWebUtil.shallowCopy(this.formData?.configurations);
    configurations.map((configuration: any) => {
      if (configuration.code === CONFIGURATION_CONSTANT.CHECK_IN_TIME) {
        configuration.value = Number(configuration.value) ? configuration.value / 1000 : null
      }
      this.configurations.push(this.createItem(configuration));
    });
    this.checkRequiredValidation();
  }

  createItem(configuration: Configuration): FormGroup {
    return this.formBuilder.group(configuration);
  }

  checkRequiredValidation() {
    if (this.formData.businessName) {
      this.configurationForm.controls['deviceId'].clearValidators();;
      this.configurationForm.controls['endUserId'].clearValidators();;
    }
    this.configurationForm.controls['deviceId'].updateValueAndValidity();
    this.configurationForm.controls['endUserId'].updateValueAndValidity();
  }

  linkDevice() {
    if (this.configurationForm.valid) {
      this.loading = true;
      if (this.formData?.mode === 'edit') {
        let data = {
          id: this.formData?.businessName ? this.formData.id : this.formData?.userId,
          configurations: this.configurations.value.map((configuration: any) => {
            if (configuration.code === CONFIGURATION_CONSTANT.CHECK_IN_TIME) {
              return { ...configuration, value: Number(configuration.value) ? configuration.value * 1000 : 2000 }
            } else {
              return configuration;
            }
          })
        }
        if (this.formData?.businessName) {
          this.organizationService.updateConfigurations(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
              next: (response) => {
                this.toasterService.show('Success', response.message);
                this.modalRef.close(response.data);
                this.dataService.sendData(response.data);
              },
              error: (e) => {
              }
            });
        } else {
          this.userService.updateConfigurations(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
              next: (response) => {
                this.toasterService.show('Success', response.message);
                this.modalRef.close(response.data);
                this.dataService.sendData(response.data);
              },
              error: (e) => {
              }
            });
        }
      } else {
        let data = {
          ...this.configurationForm.value,
          configurations: this.configurations.value.map((configuration: any) => {
            if (configuration.code === CONFIGURATION_CONSTANT.CHECK_IN_TIME) {
              return { ...configuration, value: Number(configuration.value) ? configuration.value * 1000 : 2000 }
            } else {
              return configuration;
            }
          })
        }
        this.userService.linkDevice(data)
          .pipe(finalize(() => this.loading = false))
          .subscribe({
            next: (response) => {
              // this.tableConfig.tableDatas = response.data.rows;
              this.toasterService.show('Success', response.message);
              this.modalRef.close(response.data);
              this.dataService.sendData(response.data);
            },
            error: (e) => {
            }
          });
      }
    }
  }

  cancel() {
    this.modalRef.close();
    (this.configurationForm.controls['configurations'] as FormArray).clear();
    if (!this.formData?.mode) {
      const config = this.nightWebUtil.shallowCopy(this.config);
      config.modalClass = 'modal-dialog-scrollable modal-md';
      config.data.formData = this.formData;
      if (this.formData?.module === "Device") {
        config.data.title = 'Link Device';
        this.linkDeviceComponent = this.modalService.open(LinkDeviceModalComponent, config);
      } else if (this.formData?.module === "User") {
        this.linkUserComponent = this.modalService.open(LinkUserModalComponent, config);
      }
    }
  }
}
