import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { NightwebUtil } from '../utils';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClientService,
    private nightwebUtil: NightwebUtil
  ) { }

  getAllAdministrators(query?: any) {
    return this.http.get(API_CONSTANTS.ADMINS, query)
    .pipe(
      map((res: any) => {
        if (res?.data?.rows) {
          res.data.rows = res.data.rows.map((admin: any) => {
            admin.displayName = this.nightwebUtil.getDisplayName(admin.demographic);
            return admin;
          });
        }
        return res;
      })
    );
  }

  getById(id: any) {
    return this.http.get(API_CONSTANTS.ADMINS + `/${id}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSelf() {
    return this.http.get(API_CONSTANTS.ADMINS + API_CONSTANTS.SELF)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateSelf(data: any) {
    return this.http.put(API_CONSTANTS.ADMINS + API_CONSTANTS.SELF, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getAdvocates(query?: any) {
    return this.http.get(API_CONSTANTS.ADMINS + API_CONSTANTS.ADVOCATES, query)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  invite(id: any) {
    return this.http.get(API_CONSTANTS.ADMINS + `/${id}` + '/invite')
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  create(data: any) {
    return this.http.post(API_CONSTANTS.ADMINS, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  addAdmin(data: any) {
    return this.http.post(API_CONSTANTS.ADMINS, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  update(data: any) {
    return this.http.put(API_CONSTANTS.ADMINS + `/${data.id}`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(id: string) {
    return this.http.remove(API_CONSTANTS.ADMINS + `/${id}`)
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
