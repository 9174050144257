export const API_CONSTANTS = {
    AUTH: '/auth',
    LOGIN: '/login',
    REGISTER: '/register',
    DEVICE: '/devices',
    END_USERS: '/end-users',
    CHECK_IN: '/check-in',
    VERIFY_CODE: '/verify-mfa-code',
    RESEND_CODE: '/resend-mfa-code',
    FORGOT_PASSWORD: '/forget-password',
    SET_PASSWORD: '/set-password',
    CHANGE_PASSWORD: '/change-password',
    LINK_DEVICE: '/link-device',
    UNLINK_DEVICE: '/unlink-device',
    ORGANIZATIONS: '/organizations',
    CONFIGURATIONS: '/configurations',
    ADMINS: '/admins',
    ROLES: '/roles',
    ACTIVITY_LOGS: '/activity-logs',
    ADVOCATES: '/advocates',
    SELF: '/self',
    LOOKUP: '/lookups',
    REFRESH: '/refresh',
    LOGOUT: '/logout'
};