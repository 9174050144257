<form [formGroup]="administratorForm" autocomplete="off">
    <div class="d-flex justify-content-between mb-3">
        <div class="d-flex justify-content-start">
            <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
        </div>
        <div class="d-flex justify-content-end">
            <app-select [form]="administratorForm" [control]="administratorForm.controls['limit']" [controlName]="'limit'"
                [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'"
                (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline me-2"></app-select>
            <button type="button" class="btn btn-primary" (click)="openAddAdministratorModal()" *ngIf="isWriteAccessible">
                <i-bs name="plus"></i-bs> Add Administrator
            </button>
        </div>
    </div>

    <div class="mt-3">
        <app-table [tableConfig]="tableConfig" [module]="'Organization module'" [loading]="loading"
            [notFoundMessage]="notFoundMessage" [tableHeadClass]="tableHeadClass" (sendEvent)="receiveEvent($event)">
        </app-table>
    </div>
</form>