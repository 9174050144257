import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, STATUS_CODES } from 'src/app/constants';
import { DeviceService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { InputType, TableConfig } from '../../common/interface';
import { ConfigurationModalComponent } from '../configuration-modal/configuration-modal.component';

@Component({
  selector: 'app-link-device-modal',
  templateUrl: './link-device-modal.component.html',
  styleUrls: ['./link-device-modal.component.scss']
})
export class LinkDeviceModalComponent implements OnInit {

  linkDeviceForm!: FormGroup;

  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  selectedDeviceId: string = '';
  searchKeyword: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  status_codes = STATUS_CODES;
  notFoundMessage = NODATA_MESSAGES.NO_DEVICES;
  tableConfig: TableConfig = {
    headerElements: [
      'Device', 'Serial No.', 'Phone', 'EIN'
    ],
    bodyElements: [
      { value: 'name', addonType: InputType.CHECKBOX },
      { value: 'serialNumber' },
      { value: 'associatedPhone', dataType: 'phone' },
      { value: 'eidNumber' }
    ],
    tableDatas: [],
    actionItems: []
  }
  tableHeadClass: string = 'bg-white text-gray-dark'

  constructor(
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private deviceService: DeviceService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<LinkDeviceModalComponent>
  ) {
    this.setLinkDeviceForm();
   }

  ngOnInit(): void {
    this.getAllActiveDevices();
  }

  setLinkDeviceForm() {
    this.linkDeviceForm = this.formBuilder.group({
      keyword: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: []
    });
  }

  getAllActiveDevices(filter?: any) {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.deviceService.getAllDevices({ ...filter, activeUser: null, organizationId: this.formData?.organizationId })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.tableConfig.tableDatas = response.data.rows;
        },
        error: (e) => {
        }
      })
  }

  setupConfiguration() {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Configuration';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = this.formData;
    config.data.confirmButtonLabel = 'Link Device';
    config.data.formData.module = 'Device';
    config.data.formData.module = 'organization';
    config.data.formData.mode = 'add';
    config.data.formData.deviceId = this.selectedDeviceId;
    this.modalRef.close();
    this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
    // this.configurationModalConfig.onClose.subscribe((data: any) => {
    //   if (data) {
    //   }
    // });

  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.linkDeviceForm.patchValue({ keyword });
    const filterParams = this.nightWebUtil.getFilterParams(this.linkDeviceForm.value)
    this.getAllActiveDevices(filterParams);
  }

  receiveEvent({ action, event: { target: { checked } }, object: { id } }: any) {
    if (action === 'change') {
      if (checked) {
        this.selectedDeviceId = id;
      } else {
        this.selectedDeviceId = '';
      }
    }
  }
}
