<div [class]="tableWrapperClass">
    <table class="table table-striped">
        <thead [class]="tableHeadClass">
            <tr>
                <th *ngFor="let head of tableConfig?.headerElements">{{ head }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="!loading; then isLoaded; else notLoaded"></ng-container>
            <ng-template #isLoaded>
                <tr *ngFor="let data of tableConfig?.tableDatas; let i = index;" class="text-nowrap">
                    <td [class.cursor-pointer]="field?.navigation" *ngFor="let field of tableConfig?.bodyElements"
                        (click)="navigate(field, data)">
                        <ng-template [ngIf]="field?.addonType" [ngIfElse]="textOnly">
                            <ng-container [ngSwitch]="field?.addonType === inputType.CHECKBOX">
                                <div class="form-check">
                                    <input type="{{field?.addonType}}" class="form-check-input"
                                        id="{{field?.value}}-{{i}}" [checked]="currentCheckedIndex === i"
                                        (change)="change($event, data, i)" />
                                    <label class="form-check-label" for="{{field?.value}}-{{i}}">{{
                                        getValueByField(field, data) }}</label>
                                </div>
                            </ng-container>
                        </ng-template>
                        <ng-template #textOnly>
                            <ng-container [ngSwitch]="field.dataType">
                                <div *ngSwitchCase="'address'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'contact'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'updated'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'created'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'module'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'eventType'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchCase="'adminDemographic'">
                                    <div [innerHTML]="getValueByField(field, data)"></div>
                                </div>
                                <div *ngSwitchDefault>
                                    {{ getValueByField(field, data) }}
                                </div>
                            </ng-container>
                            <!-- {{ getValueByField(field, data) }} -->
                        </ng-template>
                        <div *ngIf="field?.actionButton && tableConfig?.actionItems?.length">
                            <div mdbDropdown class="dropdown">
                                <a role="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                    <i-bs name="three-dots-vertical"></i-bs>
                                </a>
                                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="dropdownMenuButton">
                                    <ng-container *ngFor="let item of tableConfig?.actionItems">
                                        <li *ngIf="item?.name ? item.name : getActionLabel(item, data)"><a
                                                class="dropdown-item" role="button"
                                                (click)="performAction(data, item?.eventName)">
                                                {{item?.name ? item.name : getActionLabel(item, data)}}
                                            </a></li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>

                    </td>
                </tr>
            </ng-template>
            <ng-template #notLoaded>
                <!-- <div class="pb-2" *ngIf="loading">
                    <app-loader [loader]="loading"></app-loader>
                </div> -->
            </ng-template>

        </tbody>
    </table>
</div>

<div class="pt-3 pb-4 mb-4 text-center" *ngIf="!loading && tableConfig && tableConfig.tableDatas.length == 0">
    <i-bs name="journal-text" width="2rem" height="2rem"></i-bs>
    <p class="mt-2">{{notFoundMessage}}</p>
</div>

<div class="mt-4" *ngIf="loading && tableConfig && tableConfig.tableDatas.length == 0">
    <app-loader [loader]="loading"></app-loader>
</div>