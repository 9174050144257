<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{
        title + ' Organization'
        }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="organizationForm" autocomplete="off">
        <div class="md-form mb-2">
            <label for="businessName" class="form-label">Business Name *</label>
            <app-input [name]="'businessName'" [type]="'text'" [control]="organizationForm.controls['businessName']"
                [submitted]="submitted" [required]="true">
            </app-input>
        </div>
        <div class="md-form mb-2">
            <div class="d-flex justify-content-between">
                <label for="businessName" class="form-label">Display Name</label>
                <!-- <div class="form-check">
                    <input mdbCheckbox class="form-check-input" type="checkbox" formControlName="sameAsBusinessName"
                        id="sameAsBusinessName">
                    <label class="form-check-label" for="sameAsBusinessName">
                        Same as business name
                    </label>
                </div> -->
                <app-checkbox [form]="organizationForm" [checkBoxId]="'sameAsBusinessName'"
                    [checkBoxLabel]="'Same as business name'"
                    [control]="organizationForm.controls['sameAsBusinessName']"
                    (emitCheckEvent)="receiveCheckEvent($event)"></app-checkbox>
            </div>
            <app-input [name]="'displayName'" [type]="'text'" [control]="organizationForm.controls['displayName']"
                [submitted]="submitted">
            </app-input>
        </div>
        <div class="md-form mb-2">
            <label for="email" class="form-label">Email</label>
            <app-input [name]="'email'" [type]="'text'" [control]="organizationForm.controls['email']"
                [submitted]="submitted" [required]="true">
            </app-input>
        </div>
        <div class="md-form mb-2">
            <label for="phone" class="form-label">Phone Number</label>
            <app-input [name]="'phone'" [type]="'text'" [control]="organizationForm.controls['phone']"
                [mask]="phoneMask" [submitted]="submitted" [required]="true">
            </app-input>
        </div>
        <div formGroupName="address">
            <label for="addressLine1" class="form-label">Address</label>
            <div class="row gx-3 mb-2">
                <div class="col-8">
                    <app-input [name]="'addressLine1'" [type]="'text'" [control]="address.controls['addressLine1']"
                        [submitted]="submitted" [required]="true" [placeholder]="'Address Line 1'">
                    </app-input>
                </div>
                <div class="col-4">
                    <app-input [name]="'addressLine2'" [type]="'text'" [control]="address.controls['addressLine2']"
                        [submitted]="submitted" [placeholder]="'Address Line 2'">
                    </app-input>
                </div>
            </div>
            <div class="row gx-3">
                <div class="col-4">
                    <app-input [name]="'city'" [type]="'text'" [control]="address.controls['city']"
                        [submitted]="submitted" [required]="true" [placeholder]="'City'">
                    </app-input>
                </div>
                <div class="col-4">
                    <app-autocomplete [form]="address" [control]="address.controls['state']" [filterKeys]="['value']"
                        [data]="states" [name]="'label'" [value]="'value'" [filterKeys]="['value']" [placeholder]="'State'"
                        (emitSelectedValue)="receiveSelectedValue($event)">
                    </app-autocomplete>
                </div>
                <div class="col-4">
                    <app-input [name]="'zip'" [type]="'text'" [control]="address.controls['zip']" [mask]="'0*'"
                        [maxLength]="5" [submitted]="submitted" [required]="true" [placeholder]="'Zip'">
                    </app-input>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="organizationForm.invalid || loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        {{formData ? 'Update': 'Save'}}</button>
</div>