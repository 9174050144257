import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from 'src/app/constants';
import { ToasterService, OrganizationService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { PopConfirmComponent } from '../common';
import { TableConfig } from '../common/interface';
import { AddEditOrganizationModalComponent, ConfigurationModalComponent } from '../modals';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  organizationForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  organizations: any[] = [];
  searchKeyword: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  organizationAddEditModalComponent!: MdbModalRef<AddEditOrganizationModalComponent>;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;
  isWriteAccessible: boolean = this.nightWebUtil.isAccessible('Organizations', 'W');
  isOrganizationConfigurable: boolean = this.nightWebUtil.isAccessible('Organization Configurations', 'W');

  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Name', 'Address', 'Contact', 'Updated', ''
    ],
    bodyElements: [
      { value: 'businessName', navigation: true },
      { value: 'address', dataType: 'address' },
      { value: ['email', 'phone'], dataType: 'contact' },
      { value: ['updatedAt', 'name'], dataType: 'updated', objectKey: 'updatedBy', navigation: true },
      { value: '', actionButton: true },
    ],
    tableDatas: [],
    actionItems: [
    ]
  };
  tableHeadClass: string = 'bg-gray-light text-gray-dark'
  notFoundMessage = NODATA_MESSAGES.NO_ORGANIZATIONS;

  constructor(
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private popconfirmService: MdbPopconfirmService,
    private organizationService: OrganizationService
  ) {
    this.setOrganizationForm();
    if (this.isWriteAccessible) {
      this.tableConfig.actionItems?.unshift(
        { name: 'Edit', eventName: 'editModule' },
        { name: 'Delete', icon: 'trash', eventName: 'deleteModule' }
      );
    }
    if (this.isOrganizationConfigurable) {
      this.tableConfig.actionItems?.splice(1, 0,
        { name: 'Edit Configuration', eventName: 'editConfiguration' }
      );
    }
  }

  ngOnInit(): void {
    this.getAllOrganizations();
  }

  setOrganizationForm() {
    this.organizationForm = this.formBuilder.group({
      keyword: [''],
      sourceOfRequest: [''],
      createdFrom: [''],
      createdTo: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: []
    });
  }

  getAllOrganizations() {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.organizationService.getAllOrganizations(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.organizations = response.data.rows;
          this.tableConfig.tableDatas = this.organizations.map(organization => {
            return {
              ...organization,
              updatedBy: {
                ...organization.updatedBy,
                updatedAt: organization.updatedAt
              }
            }
          });
        },
        error: (e) => {
        }
      })
  }

  openAddOrganizationModal(organization?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = organization ? 'Edit' : 'Add';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = organization;
    this.organizationAddEditModalComponent = this.modalService.open(AddEditOrganizationModalComponent, config);
    this.organizationAddEditModalComponent.onClose.subscribe((data: any) => {
      if (data) {
        this.getAllOrganizations();
      }
    });
  }

  openEditConfigurationModal(organization?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Configuration';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = organization;
    config.data.formData.mode = 'edit';
    config.data.formData.organizationId = organization.id;
    config.data.formData.module = 'organization';
    config.data.confirmButtonLabel = 'Update';

    this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
    this.configurationModalConfig.onClose.subscribe((data: any) => {
      if (data) {
        this.getAllOrganizations();
      }
    });
  }

  deleteOrganization(event: Event, organization: any) {
    const target = event.target as HTMLElement;
    const config = this.nightWebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this.popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.organizationService.delete(organization.id)
        .subscribe({
          next: (response) => {
            this.organizations.splice(this.organizations.findIndex(x => x.id == organization.id), 1);
            this.tableConfig.tableDatas = this.organizations;
            this.tableConfig.tableDatas = this.organizations.map(organization => {
              return {
                ...organization,
                updatedBy: {
                  ...organization.updatedBy,
                  updatedAt: organization.updatedAt
                }
              }
            });
            this.toasterService.show("Success", MESSAGE_CONSTANTS.ORG_REMOVED);
          },
          error: (e) => {
          }
        })
    });
  }

  receiveEvent({ action, event, object }: any) {
    if (action === 'edit') {
      this.openAddOrganizationModal(object);
    } else if (action === 'delete') {
      this.deleteOrganization(event, object);
    } else if (action === 'editConfiguration') {
      this.openEditConfigurationModal(object);
    }
  }

  receiveSelectedValue(event: any) {
    if (event) {
      this.organizationForm.patchValue({
        [event.controlName]: event.value
      });
      if (event.controlName === 'limit') {
        this.tableConfig.tableDatas = [];
        this.filter = this.nightWebUtil.getFilterParams(this.organizationForm.value)
        this.getAllOrganizations();
      }
    }
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.organizationForm.patchValue({ keyword });
    this.filter = this.nightWebUtil.getFilterParams(this.organizationForm.value)
    this.getAllOrganizations();
  }

}
