<form [formGroup]="profileInformationForm" autocomplete="off">
    <div class="d-flex flex-column w-50">
        <div formGroupName="demographic">
            <div class="row gx-3 mb-2">
                <div class="col-4">
                    <label for="firstName" class="form-label">First Name</label>
                    <app-input [name]="'firstName'" [type]="'text'" [control]="demographic.controls['firstName']"
                        [submitted]="submitted" [required]="true">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="middleName" class="form-label">Middle Name</label>
                    <app-input [name]="'middleName'" [type]="'text'" [control]="demographic.controls['middleName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="lastName" class="form-label">Last Name</label>
                    <app-input [name]="'lastName'" [type]="'text'" [control]="demographic.controls['lastName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
            </div>
            <div class="row mb-2">
                <label for="email" class="form-label">Email</label>
                <app-input [name]="'email'" [type]="'text'" [control]="demographic.controls['email']"
                    [submitted]="submitted" [required]="true">
                </app-input>
            </div>
            <div class="row mb-2">
                <label for="phone" class="form-label">Phone Number</label>
                <app-input [name]="'phone'" [type]="'text'" [control]="demographic.controls['phone']" [mask]="phoneMask"
                    [submitted]="submitted">
                </app-input>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <!-- <button type="button" class="btn btn-gray-lightest" (click)="cancel()">Discard Changes</button> -->
            <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="demographic.invalid || loading">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>Save
                Changes</button>
        </div>
    </div>
</form>