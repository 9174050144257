export const MESSAGE_CONSTANTS = {
    UNAUTHORIZED: "User unauthorized. Please login to continue.",
    SESSION_EXPIRED: "Session expired. Please re-login.",
    FORBIDDEN: "Forbidden.",
    SERVER_ERROR: "Server error. Please contact admin.",
    TIMEOUT: "Request timed out. Please contact admin.",
    BAD_REQUEST: "Invalid payload",
    SERVICE_UNAVAILABLE: 'Service unavailable. Please try again later.',

    // DEVICE
    DEVICE_UPDATED: "Device info updated successfully.",
    DEVICE_ADDED: "Device added successfully.",
    DEVICE_REMOVED: "Device removed successfully.",
    DEVICE_UNLINKED: "Device unlinked successfully.",

    // USER
    USER_UPDATED: "User info updated successfully.",
    USER_ADDED: "User added successfully.",
    USER_REMOVED: "End user removed successfully.",

    // Irreversible
    DELETE_CONFIRMATION: "This action is irreversible. Are you sure you want to delete?",

    // OTP CODE
    NO_CODE: "OTP code was not entered. Please enter OTP code to verify.",
    VERIFIED: "OTP code verified.",

    // ORGANIZATION
    ORG_ADDED: "Organization added successfully.",
    ORG_UPDATED: "Organization updated successfully.",
    ORG_REMOVED: "Organization removed successfully.",

    // ADMIN
    ADMIN_ADDED: "Admin added sccessfully.",
    ADMIN_UPDATED: "Admin info updated successfully.",
    ADMIN_REMOVED: "Admin removed sccessfully.",
    // AUTH
    FORGOT_PASSWORD: "Password reset link has been sent to the email provided.",
    CHANGED_PASSWORD: "Password changed. Re-login to continue.",
    RESET_PASSWORD: "Password has been reset. Please login."
}