<div class="modal-header border-0">
    <h6 class="modal-title" id="exampleModalLabel">{{ title }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <p class="mb-0" [innerHTML]="body"></p>
</div>
<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">{{cancelButtonLabel}}</button>
    <button type="button" class="btn btn-primary" debounceClick
        (debounceClick)="confirm()">{{confirmButtonLabel}}</button>
</div>