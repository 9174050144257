export const MENU = [
    {
        route: '/end-users',
        resourceName: 'End Users',
        label: 'End Users',
        icon: 'person-fill',
        children: {
            route: '/end-users/:id',
            resourceName: 'End Users Activities',
            label: 'Detail',
        }
    },
    {
        route: '/devices',
        resourceName: 'Devices',
        label: 'Devices',
        icon: 'watch'
    },
    {
        route: '/organizations',
        resourceName: 'Organizations',
        label: 'Organizations',
        icon: 'diagram-3-fill'
    },
    {
        route: '/administrators',
        resourceName: 'Admins',
        label: 'Administrators',
        icon: 'person-fill'
    },
    {
        route: '/role-and-permissions',
        resourceName: 'Role and Permissions',
        label: 'Roles and Permissions',
        icon: 'shield-lock-fill'
    },
    {
        route: '/activity-logs',
        resourceName: 'Activity Logs',
        label: 'Activity Logs',
        icon: 'clock-fill'
    }
];