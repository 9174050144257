import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { Address } from 'src/app/models';
import { FormatPhone, NightwebUtil } from 'src/app/utils';
import { ActionItemType, InputType, TableConfig } from '../interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  filterBy: string[] = [];
  key = '';
  defaultDatePipeFormat: string = NIGHTWEB_APP_CONSTANT.defaultDatePipeFormat;
  shortDateTimeFormat: string = NIGHTWEB_APP_CONSTANT.shortDateTimeFormat;
  defaultTimeFormat: string = NIGHTWEB_APP_CONSTANT.defaultTimeFormat;
  inputType = InputType;
  actionItemType = ActionItemType;
  @Input() notFoundMessage!: string;
  @Input() tableConfig!: TableConfig;
  @Input() headerElements: any;
  @Input() bodyElements: any;
  @Input() tableDatas: any;
  @Input() tableHeadClass: string = 'bg-gray-light text-gray-dark';
  @Input() tableWrapperClass: string = 'table-responsive';
  @Input() actionItems: any;
  @Input() query: any;
  @Input() set keyword(key: any) {
    this.key = key;
    this.filterBy = this.bodyElements.filter((x: any) => x.value).map((y: any) => y.value);
  }
  @Input() module: any;
  @Input() iconName: any;
  @Input() loading: boolean = true;
  @Input() currentCheckedIndex!: number | null;
  @Output() sendEvent = new EventEmitter();
  @Output() sendPage = new EventEmitter();
  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  tableDataLoader = Array(3).fill(0);
  constructor(
    private datePipe: DatePipe,
    private percentPipe: PercentPipe,
    private _formatPhone: FormatPhone,
    private nightwebUtil: NightwebUtil,
    private currencyPipe: CurrencyPipe,
  ) { }

  ngOnInit(): void {
    this.notFoundMessage = this.notFoundMessage ?? `No data.`
  }

  getValueByField(field: any, obj: any) {
    let value: any;
    if (Array.isArray(field.value)) {
      if (field?.objectKey) {
        field.value.forEach((item: any) => {
          value = {
            ...value,
            [item]: obj[`${field.objectKey}`][`${item}`]
          };
        });
      } else {
        field.value.forEach((item: any) => {
          value = {
            ...value,
            [item]: obj[`${item}`]
          };
        });
      }
    } else {
      value = obj[field.value] === "" ? "" : obj[field.value] || obj[field.value] == 0 ? obj[field.value] : "";
    }
    if (field.concatString)
      value = value.toString().concat(field.concatString)
    if (field.dataType) {
      switch (field.dataType) {
        case 'currency':
          value = this.currencyPipe.transform(value);
          break;
        case 'date':
          value = (value === "" ? "" : this.datePipe.transform(value, this.defaultDatePipeFormat));
          break;
        case 'dateTime':
          value = (value === "" ? "" : this.datePipe.transform(value, this.shortDateTimeFormat, "+0000"));
          break;
        case 'time':
          value = (value === "" ? "" : this.datePipe.transform(value, this.defaultTimeFormat));
          break;
        case 'phone':
          value = this._formatPhone.phoneNumber(value);
          break;
        case 'contact':
          value = this.nightwebUtil.formatContact(value);
          break;
        case 'displayName':
          value = this.nightwebUtil.getDisplayName(value);
          break;
        case 'updated':
          value = this.nightwebUtil.formatUpdatedBy(value);
          break;
        case 'created':
          value = this.nightwebUtil.formatCreatedBy(value);
          break;
        case 'address':
          value = this.nightwebUtil.formatAddress(value);
          break;
        case 'array':
          value = this.nightwebUtil.getValue(obj[field.objectKey] ? obj[field.objectKey] : obj, field.value);
          break;
        case 'object':
          if (field.pipe) {
            value = obj[field.objectKey] ? this._formatPhone.phoneNumber(obj[field.objectKey][field.value]) : '';
          } else {
            value = obj[field.objectKey] ? obj[field.objectKey][field.value] : '';
          }
          break;
        case 'percent':
          value = value.toString().concat('%')
          break;
        case 'lteStrength':
          value = this.getLTEStrength(value);
          break;
        case 'boolean':
          value = value ? "Yes" : "No";
          break;
        case 'module':
          value = this.nightwebUtil.formatModule(value);
          break;
        case 'eventType':
          value = this.nightwebUtil.formatEventType(value);
          break;
        case 'adminDemographic':
          value = this.nightwebUtil.getDemographic(value, field);
          break;
        default:
          break;
      }
    }
    return value;
  }

  getLTEStrength(value: any) {
    switch (value) {
      case 0:
        return 'Low';
      case 1:
        return 'Mid';
      case 2:
        return 'Good';
      case 3:
        return 'Excellent';
      default:
        return 'N/A';
    }
  }

  change(event: any, data: any, index: number) {
    this.currentCheckedIndex = index;
    this.emitEvent({
      action: 'change',
      event,
      object: data
    });
  }

  emitEvent(data: any) {
    this.sendEvent.emit(data);
  }

  navigate(field: any, data: any) {
    if (field?.navigation) {
      this.emitEvent({
        action: 'navigation',
        event,
        object: data
      });
    }
  }

  performAction(data: any, eventName?: string) {
    switch (eventName) {
      case 'editModule':
        this.emitData(data, 'edit');
        break;
      case 'deleteModule':
        this.emitData(data, 'delete');
        break;
      case 'linkUserModule':
        this.emitData(data, 'linkUserModule');
        break;
      case 'editConfiguration':
        this.editConfiguration(data);
        break;
      default:
        break;
    }
  }

  emitData(data: any, action: string) {
    this.emitEvent({
      action,
      event,
      object: data
    });
  }

  edit(data: any) {
    this.emitEvent({
      action: 'edit',
      event,
      object: data
    });
  }

  delete(data: any) {
    this.emitEvent({
      action: 'delete',
      event,
      object: data
    });
  }

  editConfiguration(data: any) {
    this.emitEvent({
      action: 'editConfiguration',
      event,
      object: data
    });
  }

  getActionLabel(item: any, data: any) {
    switch (item?.eventChecker) {
      case 'Link/Unlink User':
        if (!data?.activeUser) {
          return 'Link User';
        } else {
          return 'Unlink User';
        }
      case 'Edit Configuration Devices':
        if (data?.activeUser) {
          return 'Edit Configuration';
        }
        return '';
      default:
        return '';
    }
  }
}
