import { Routes } from "@angular/router";

import * as components from '../components';
import { RoleGuard } from "../guards";

export const RouteSecure: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/end-users' },
    {
        path: 'activity-logs',
        component: components.ActivityLogComponent,
        data: [{ resourceName: 'Activity Logs', accessType: 'R' }]
    },
    {
        path: 'administrators',
        component: components.AdministratorComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'Admins', accessType: 'R' }]
    },
    {
        path: 'devices',
        component: components.DeviceComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'Devices', accessType: 'R' }]
    },
    {
        path: 'end-users',
        component: components.EndUserComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'End Users', accessType: 'R' }]
    },
    {
        path: 'end-users/:id',
        component: components.UserDetailComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'End User Activities', accessType: 'R' }]
    },
    {
        path: 'profile',
        component: components.MyProfileComponent
    },
    {
        path: 'role-and-permissions',
        component: components.RolesAndPermissionComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'Role and Permissions', accessType: 'R' }]
    },
    {
        path: 'organizations',
        component: components.OrganizationComponent,
        canActivate: [RoleGuard],
        data: [{ resourceName: 'Organizations', accessType: 'R' }]
    },
    { path: '', component: components.EndUserComponent }
];