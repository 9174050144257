<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{ title }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
    <!-- <form [formGroup]="assignPermissionForm" autocomplete="off"> -->
    <div class="table-responsive">
        <table class="table align-middle">
            <thead class="bg-white text-gray-dark">
                <tr>
                    <th>Permission</th>
                    <th>Read</th>
                    <th>Write</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let menu of accessibleMenus; let i = index">
                    <td>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="menu-{{i}}" checked disabled>
                            <label class="form-check-label"
                                for="menu-{{i}}">{{menu?.resourceName}}</label>
                        </div>
                    </td>
                    <td>
                        <input type="radio" class="form-check-input" name="accessType-{{i}}"
                            id="read-menu-{{i}}" [checked]="menu?.accessType === 'R'" disabled/>
                    </td>
                    <td>
                        <input type="radio" class="form-check-input" name="accessType-{{i}}"
                            id="write-menu-{{i}}"
                            [checked]="menu?.accessType === 'W' || menu?.accessType === 'R/W' " disabled/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- </form> -->
</div>
<!-- <div class="modal-footer pt-0 d-flex justify-content-between">
    <button type="button" class="btn btn-gray-lightest" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="update()">{{ confirmButtonLabel }}</button>
</div> -->