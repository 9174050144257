import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, MODULE, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from 'src/app/constants';
import { DataService, DeviceService, ToasterService, UserService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { PopConfirmComponent } from '../common';
import { TableConfig } from '../common/interface';
import { AddEditDeviceModalComponent, ConfigurationModalComponent, ConfirmationModalComponent, LinkUserModalComponent } from '../modals';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  deviceForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateTimeFormat = NIGHTWEB_APP_CONSTANT.defaultDateTimeFormat;
  devices: any[] = [];
  searchKeyword: string = '';
  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;
  linkUserComponent!: MdbModalRef<LinkUserModalComponent>;
  deviceAddEditComponent!: MdbModalRef<AddEditDeviceModalComponent>;
  configurationModalConfig!: MdbModalRef<ConfigurationModalComponent>;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;
  isWriteAccessible: boolean = this.nightWebUtil.isAccessible('Devices', 'W');
  isSuperAdmin: boolean = !this.nightWebUtil.isSuperAdmin();
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Model Number', 'Serial Number', 'EIN', 'Device Phone', 'Device Name', 'Linked User', 'Updated', ''
    ],
    bodyElements: [
      { value: 'modelNumber', navigation: true },
      { value: 'serialNumber', navigation: true },
      { value: 'eidNumber', navigation: true },
      { value: 'associatedPhone', dataType: 'phone', navigation: true },
      { value: 'name', navigation: true },
      { value: 'name', dataType: 'object', objectKey: 'activeUser', navigation: true },
      { value: ['updatedAt', 'name'], dataType: 'updated', objectKey: 'updatedBy', navigation: true },
      { value: '', actionButton: true },
    ],
    tableDatas: [],
    actionItems: [
    ]
  }
  section = MODULE.DEVICES;
  notFoundMessage = NODATA_MESSAGES.NO_DEVICES;
  tableHeadClass: string = 'bg-gray-light text-gray-dark'
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private userService: UserService,
    private nightWebUtil: NightwebUtil,
    private deviceService: DeviceService,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private popconfirmService: MdbPopconfirmService
  ) {
    this.setDeviceForm();
    if (this.isWriteAccessible) {
      this.tableConfig.actionItems?.unshift(
        { name: 'Edit', eventName: 'editModule' },
        { name: null, eventChecker: 'Link/Unlink User', eventName: 'linkUserModule' },
        { name: null, eventChecker: 'Edit Configuration Devices', eventName: 'editConfiguration' },
        { name: 'Delete', eventName: 'deleteModule' }
      );
    }
  }

  ngOnInit(): void {
    this.getAllDevices();

    this.dataService.getData().subscribe(
      (data) => {
        if (data) {
          this.getAllDevices();
        }
      }
    )
  }

  setDeviceForm() {
    this.deviceForm = this.formBuilder.group({
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [''],
      keyword: [''],
      status: [''],
      state: [''],
      orderCreatedFrom: [''],
      orderCreatedTo: [''],
      dob: [''],
      keywords: this.formBuilder.array([])
    });
  }

  getAllDevices() {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.deviceService.getAllDevices(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.devices = response.data.rows;
          this.tableConfig.tableDatas = this.devices.map(device => {
            return {
              ...device,
              updatedBy: {
                ...device.updatedBy,
                updatedAt: device.updatedAt
              }
            }
          });
        },
        error: (e) => {
        }
      })
  }

  openAddEditDeviceModal(device?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = device ? 'Edit' : 'Add';
    config.data.formData = device;
    config.modalClass = 'modal-dialog-scrollable modal-md';
    this.deviceAddEditComponent = this.modalService.open(AddEditDeviceModalComponent, config);
    this.deviceAddEditComponent.onClose.subscribe((data: any) => {
      if (data) {
        this.getAllDevices();
      }
    });
  }

  deleteDevice(event: Event, device: any) {
    const target = event.target as HTMLElement;
    const config = this.nightWebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete ${device.name}`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this.popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.deviceService.delete(device.id)
        .subscribe({
          next: (response) => {
            this.devices.splice(this.devices.findIndex(x => x.id == device.id), 1);
            this.tableConfig.tableDatas = this.devices.map(device => {
              return {
                ...device,
                updatedBy: {
                  ...device.updatedBy,
                  updatedAt: device.updatedAt
                }
              }
            });
            this.toasterService.show("Success", MESSAGE_CONSTANTS.DEVICE_REMOVED);
          },
          error: (e) => {
          }
        })
    });
  }

  openLinkUserModal(device?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    if (!device?.activeUser) {
      config.data.title = 'Link User';
      config.modalClass = 'modal-dialog-scrollable modal-md';
      config.data.formData = device;
      config.data.formData.selectedDeviceId = device.id;
      config.data.confirmButtonLabel = 'Link User';
      this.linkUserComponent = this.modalService.open(LinkUserModalComponent, config);
      this.linkUserComponent.onClose.subscribe((data: any) => {
        if (data) {
          this.getAllDevices();
        }
      });
    } else {
      // Unlink
      config.data.confirmButtonLabel = 'Unlink';
      config.data.formData = device;
      config.data.title = `Unlink User`;
      config.data.body = `Are you sure you want to unlink <strong>${device?.name}</strong> from <strong>${device?.activeUser?.name}</strong>?`;
      this.confirmationModalRef = this.modalService.open(ConfirmationModalComponent, config);
      this.confirmationModalRef.onClose.subscribe((data: any) => {
        if (data) {
          const unlink = {
            deviceId: data?.id,
            endUserId: data?.activeUser?.endUserId,
            reason: ''
          }
          this.unlinkUser(unlink);
        }
      });
    }
  }

  openEditConfigurationModal(device?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = 'Configuration';
    config.modalClass = ' modal-dialog-scrollable modal-md';
    config.data.formData = device;
    config.data.formData.deviceId = device.id;
    config.data.formData.userId = device?.activeUser?.endUserId;
    config.data.formData.mode = 'edit';
    config.data.formData.module = 'user';
    config.data.confirmButtonLabel = 'Update';
    this.userService.getUserById(device?.activeUser?.endUserId)
      .subscribe({
        next: (response) => {
          if (response.data) {
            config.data.formData.configurations = response.data?.configurations;
            this.configurationModalConfig = this.modalService.open(ConfigurationModalComponent, config);
          }
        },
        error: (e) => {
        }
      })
  }

  unlinkUser(data: any) {
    this.userService.unLinkDevice(data)
      .subscribe({
        next: (response) => {
          this.getAllDevices();
          this.toasterService.show('Success', MESSAGE_CONSTANTS.DEVICE_UNLINKED);
        },
        error: (e) => {
        }
      })
  }

  receiveSelectedValue(event: any) {
    this.deviceForm.patchValue({
      [event.controlName]: event.value
    });
    if (event.controlName === 'limit') {
      this.filter = this.nightWebUtil.getFilterParams(this.deviceForm.value)
      this.getAllDevices();
    }
  }

  receiveEvent({ action, event, object }: any) {
    if (action === 'edit') {
      this.openAddEditDeviceModal(object);
    } else if (action === 'delete') {
      this.deleteDevice(event, object);
    } else if (action === 'navigation') {
      if (object?.activeUser && Object.keys(object?.activeUser).length) {
        const { endUserId } = object?.activeUser;
        this.router.navigate([`/end-users/${endUserId}`]);
      }
    } else if (action === 'linkUserModule') {
      this.openLinkUserModal(object);
    } else if (action === 'editConfiguration') {
      this.openEditConfigurationModal(object);
    }
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.deviceForm.patchValue({ keyword });
    this.filter = this.nightWebUtil.getFilterParams(this.deviceForm.value)
    this.getAllDevices();
  }
}
