import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES, USER_STATUS } from 'src/app/constants';
import { UserService } from 'src/app/services';
import { DateUtility, NightwebUtil } from 'src/app/utils';
import { TableConfig } from '../common/interface';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  userDetailForm!: FormGroup;
  submitted: boolean = false;
  userId!: string;
  user!: any;
  userCheckinData: any[] = [];
  loading: boolean = false;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  dateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  mdbDateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  userStatus = USER_STATUS;

  userInitials: string = '';

  tableConfig: TableConfig = {
    headerElements: [
      'Emergency', 'TimeStamp', 'GPS', 'Heart Rate', 'Signal Strength', 'LTE Strength', 'Battery Status', 'Charging', 'On Wrist'
    ],
    bodyElements: [
      { value: 'isEmergencyState', dataType: 'boolean' },
      { value: 'checkInTime', dataType: 'dateTime' },
      { value: 'latLong' },
      { value: 'heartRate', concatString: ' bpm' },
      { value: 'signalStrength', concatString: ' bar' },
      { value: 'lteSignalStrength', dataType: 'lteStrength' },
      { value: 'batteryStatus', dataType: 'percent' },
      { value: 'isCharging', dataType: 'boolean' },
      { value: 'isOnWrist', dataType: 'boolean' },
    ],
    tableDatas: [],
    actionItems: [
      { name: 'Edit', eventName: 'editModule' },
      { name: 'Link Configuration', eventName: 'configureModule' },
      { name: 'Delete', eventName: 'deleteModule' }
    ]
  }
  notFoundMessage = NODATA_MESSAGES.NO_USER_ACTIVITIES;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dateUtil: DateUtility,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private nightwebUtil: NightwebUtil
  ) {
    this.setuserDetailForm();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.userId = params['id'];
        this.fetchUserAndUserActivity();
      }
    })
  }

  ngOnInit(): void {
  }

  setuserDetailForm() {
    this.userDetailForm = this.formBuilder.group({
      keyword: [],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [],
      createdFrom: [],
      createdTo: []
    })
  }

  fetchUserAndUserActivity() {
    this.loading = true;
    forkJoin([
      this.userService.getUserById(this.userId),
      this.userService.getUserActivities(this.userId)
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [userDetail, checkInData] = response;
          this.user = userDetail.data;
          if (this.user?.demographic?.address.length) {
            const [address] = this.user?.demographic?.address
            if (this.user?.demographic?.address) {
              this.user.demographic.address = {
                home: address
              }
            }
          }
          if (this.user?.status.toLowerCase() !== USER_STATUS.ACTIVATED.toLowerCase() && this.user?.status.toLowerCase() !== USER_STATUS.IN_PROGRESS.toLowerCase()) {
            this.router.navigate(['/end-users']);
            return;
          }
          this.getUserInitials(this.user?.demographic);
          this.userCheckinData = checkInData.data.rows.map((checkIn: any) => {
            return {
              ...checkIn,
              latLong: this.concatLatLong(checkIn?.gps)
            }
          });
          this.tableConfig.tableDatas = this.userCheckinData;
        }
      })
  }

  getUserActivities(filter?: any) {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.userService.getUserActivities(this.userId, filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.getUserInitials(this.user?.demographic);
          this.userCheckinData = response.data.rows.map((checkIn: any) => {
            return {
              ...checkIn,
              latLong: this.concatLatLong(checkIn?.gps)
            }
          });
          this.tableConfig.tableDatas = this.userCheckinData;
        }
      })
  }

  getUserInitials(user: any) {
    this.userInitials = this.nightwebUtil.getInitials(user);
  }

  concatLatLong(gps: any) {
    if (gps && (gps?.latitude == 'null' || gps?.longitude == 'null')) {
      return '';
    }
    if (gps && gps?.latitude && gps?.longitude) {
      return gps.latitude + ', ' + gps.longitude
    } else if (gps && (gps?.latitude == 'null' || gps?.longitude == 'null')) {
      return '';
    } else {
      return ''
    }
  }

  checkDateIsBefore() {
    const isBefore = this.dateUtil.checkIsBefore(this.userDetailForm.controls['createdFrom']?.value, this.userDetailForm.controls['createdTo']?.value);
    return isBefore
  }

  getMaxDate() {
    return this.dateUtil.getMaxCreatedFromDate();
  }

  getMinDate() {
    return this.dateUtil.getMinCreatedToDate(this.userDetailForm.controls['createdFrom']?.value);
  }

  getFiltered() {
    let filterParams: any = this.nightwebUtil.getFilterParams(this.userDetailForm.value);
    if (filterParams?.createdFrom) {
      filterParams.createdFrom = this.dateUtil.convertToMMddYYYY(filterParams?.createdFrom);
    }
    if (filterParams?.createdTo) {
      filterParams.createdTo = this.dateUtil.convertToMMddYYYY(filterParams?.createdTo);
    }
    this.getUserActivities(filterParams);
  }

  receiveEvent(event: any) {
  }

  receiveSelectedValue(event: any) {
    this.userDetailForm.patchValue({
      [event.controlName]: event.value ?? ''
    });
    if (event.controlName === 'limit') {
      this.getFiltered();

    }
  }
}
