import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from 'src/app/constants';
import { RoleService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { ActionItemType, TableConfig } from '../common/interface';
import { AssignPermissionModalComponent } from '../modals';

@Component({
  selector: 'app-roles-and-permission',
  templateUrl: './roles-and-permission.component.html',
  styleUrls: ['./roles-and-permission.component.css']
})
export class RolesAndPermissionComponent implements OnInit {

  loading: boolean = false;
  roles: any[] = [];

  tableConfig: TableConfig = {
    headerElements: [
      'Role Name', ''
    ],
    bodyElements: [
      { value: 'name', navigation: true },
      { value: '', actionButton: true, actionItemType: ActionItemType.BUTTON },
    ],
    tableDatas: [],
    actionItems: [
    ]
  };
  tableHeadClass: string = 'bg-gray-light text-gray-dark'
  notFoundMessage = NODATA_MESSAGES.NO_ROLES_AND_PERMISSIONS;
  // isWriteAccessible: boolean = this.nightWebUtil.isAccessible('Role and Permissions', 'W');
  isWriteAccessible: boolean = true;
  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  assignPermissionModalComponent!: MdbModalRef<AssignPermissionModalComponent>;
  
  constructor(
    private roleService: RoleService,
    private nightWebUtil: NightwebUtil,
    private modalService: MdbModalService
  ) { 
    if (this.isWriteAccessible) {
      this.tableConfig.actionItems?.unshift(
        { name: 'Assign Permission', eventName: 'assignPermission' },
      );
    }
  }

  ngOnInit(): void {
    this.getAllRoles();
  }

  getAllRoles() {
    this.roleService.getAllRoles()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.roles = response.data.rows;
          this.tableConfig.tableDatas = this.roles;
        },
        error: (e) => { }
      })
  }

  openPermissionModal(role: any) {
const {permissions, name} = role;
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = name;
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData.permissions = permissions;
    config.data.confirmButtonLabel = 'Update';
    this.assignPermissionModalComponent = this.modalService.open(AssignPermissionModalComponent, config);
    this.assignPermissionModalComponent.onClose.subscribe((data: any) => {
      if (data) {
      }
    });
  }

  receiveEvent(event: any) {

  }
}
