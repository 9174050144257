import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, forkJoin } from 'rxjs';
import { MODULE, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from 'src/app/constants';
import { ActivityLogService, LookupService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { TableConfig } from '../common/interface';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  activityLogForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateTimeFormat = NIGHTWEB_APP_CONSTANT.defaultDateTimeFormat;
  logs: any[] = [];
  modules: any[] = [];
  logTypes: any[] = [];
  searchKeyword: string = '';
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Created', 'Module', 'Event Type', 'Log Type', 'Action'
    ],
    bodyElements: [
      { value: ['createdAt', 'name'], dataType: 'created', objectKey: 'createdBy' },
      { value: ['module', 'subModule'], dataType: 'module', objectKey: 'log' },
      { value: ['type', 'subType'], dataType: 'eventType', objectKey: 'event' },
      { value: 'type', dataType: 'object', objectKey: 'log' },
      { value: 'note', dataType: 'object', objectKey: 'log', showTooltip: true },
    ],
    tableDatas: [],
    actionItems: [
    ]
  }
  section = MODULE.ACTIVITY_LOGS;
  notFoundMessage = NODATA_MESSAGES.NO_ACTIVITY_LOGS;
  tableHeadClass: string = 'bg-gray-light text-gray-dark'
  constructor(
    private formBuilder: FormBuilder,
    private nightWebUtil: NightwebUtil,
    private lookupService: LookupService,
    private activityLogService: ActivityLogService
  ) {
    this.setActivityLogForm();
  }

  ngOnInit(): void {

    this.loading = true;
    forkJoin([
      this.activityLogService.getActivityLogs(),
      this.lookupService.getSystemModules(),
      this.lookupService.getLogTypes()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [logs, modules, logTypes] = response;
          this.logs = logs.data.rows;
          this.tableConfig.tableDatas = this.logs.map(log => {
            return {
              ...log,
              createdBy: {
                ...log.createdBy,
                createdAt: log.createdAt
              }
            }
          });
          this.modules = modules.data;
          this.logTypes = logTypes.data;
        },
        error: (e) => { }
      })
  }

  setActivityLogForm() {
    this.activityLogForm = this.formBuilder.group({
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: [''],
      keyword: [''],
      logModule: [''],
      logType: ['']
    });
  }

  getActivityLogs() {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.activityLogService.getActivityLogs(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.logs = response.data.rows;
          this.tableConfig.tableDatas = this.logs.map(log => {
            return {
              ...log,
              createdBy: {
                ...log.createdBy,
                createdAt: log.createdAt
              }
            }
          });
        },
        error: (e) => {
        }
      });
  }

  receiveSelectedValue(event: any) {
    this.activityLogForm.patchValue({
      [event.controlName]: event.value
    });
    this.filter = this.nightWebUtil.getFilterParams(this.activityLogForm.value)
    this.getActivityLogs();
  }

  receiveEvent({ action, event, object }: any) {
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.activityLogForm.patchValue({ keyword });
    this.filter = this.nightWebUtil.getFilterParams(this.activityLogForm.value)
    this.getActivityLogs();
  }
}
