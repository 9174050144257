import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { AdminService, DataService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  loading: boolean = false;
  submitted: boolean = false;
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  user!: any;

  constructor(
    private dataService: DataService,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.getProfileInformation();

    this.dataService.getData().subscribe(
      (data) => {
        if (data) {
          this.getProfileInformation();
        }
      }
    )
  }

  getProfileInformation() {
    this.loading = true;
    this.adminService.getSelf()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.user = response.data;
        },
        error: (e) => {
        }
      })
  }

}
