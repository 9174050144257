import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES } from 'src/app/constants';
import { DeviceService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-add-edit-device-modal',
  templateUrl: './add-edit-device-modal.component.html',
  styleUrls: ['./add-edit-device-modal.component.scss']
})
export class AddEditDeviceModalComponent implements OnInit {

  deviceForm!: FormGroup;

  loading = false;
  submitted = false;

  title!: string;
  formData: any = {};

  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  status_codes = STATUS_CODES;

  constructor(
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private toasterService: ToasterService,
    public modalRef: MdbModalRef<AddEditDeviceModalComponent>
  ) {
    this.setDeviceForm();
  }

  ngOnInit(): void {
    if (this.formData) {
      this.deviceForm.patchValue(this.formData);
    }
  }

  setDeviceForm() {
    this.deviceForm = this.formBuilder.group({
      id: [],
      brand: [NIGHTWEB_APP_CONSTANT.defaultBrandName, Validators.required],
      name: ['', Validators.required],
      modelNumber: ['', Validators.required],
      serialNumber: ['', Validators.required],
      eidNumber: ['', Validators.required],
      associatedPhone: ['', Validators.required],
      operatingSystem: [NIGHTWEB_APP_CONSTANT.defaultOS]
    })
  }

  save() {
    if (this.deviceForm.valid) {
      this.loading = true;
      if (this.deviceForm.get('id')?.value) {
        this.deviceService.update(this.deviceForm.value)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.DEVICE_UPDATED);
              }
            },
            error: (e) => {
            }
          })
      } else {
        this.deviceService.create(this.deviceForm.value)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.DEVICE_ADDED);
              }
            },
            error: (e) => {
            }
          })
      }
    }
  }

  confirm() {
    this.modalRef.close();
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

}
