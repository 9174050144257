<div class="modal-header border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body pb-0">
    <form [formGroup]="forgotPasswordForm" autocomplete="off">
        <h4 class="mb-3 text-center">Forgot Password</h4>
        <div class="md-form">
            <label for="userName" class="form-label">Email Address</label>
            <div class="input-group input-group-lg rounded mb-2"
                [class.is-invalid]="forgotPasswordForm.controls['userName'].dirty && forgotPasswordForm.get('userName')?.invalid">
                <span class="input-group-text ps-2 pe-0"><i-bs name="envelope-fill"></i-bs></span>
                <input type="text" class="form-control px-1" id="userName" formControlName="userName" placeholder="eg john@nexion.com" />
            </div>
        </div>
    </form>
</div>
<div class="modal-footer pt-0">
    <button class="btn btn-gray-light" type="submit" mdbWavesEffect debounceClick (debounceClick)="submit()"
        [disabled]="loading || forgotPasswordForm.invalid">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        Submit
    </button>
</div>