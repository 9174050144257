import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { finalize, forkJoin } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, NODATA_MESSAGES } from 'src/app/constants';
import { AdminService, OrganizationService, RoleService, ToasterService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { PopConfirmComponent } from '../common';
import { TableConfig } from '../common/interface';
import { AddEditAdministratorModalComponent } from '../modals';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit {

  administratorForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;
  administrators: any[] = [];
  roles: any[] = [];
  organizations: any[] = [];
  searchKeyword: string = '';

  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  confirmPopConfig = NIGHTWEB_APP_CONSTANT.confirmPopConfig;
  administratorAddEditModalComponent!: MdbModalRef<AddEditAdministratorModalComponent>;
  popconfirmRef: MdbPopconfirmRef<PopConfirmComponent> | null = null;

  limits = NIGHTWEB_APP_CONSTANT.showLimits;
  defaultDateFormat = NIGHTWEB_APP_CONSTANT.defaultDateFormat;
  isWriteAccessible: boolean = this.nightWebUtil.isAccessible('Admins', 'W');
  filter: any;
  tableConfig: TableConfig = {
    headerElements: [
      'Name', 'Role', 'Contact', 'Updated', ''
    ],
    bodyElements: [
      { value: ['displayName', 'displayOrganization'], dataType: 'adminDemographic', subModuleType: 'CHIP' },
      { value: 'roleName', dataType: 'array', objectKey: 'organizations' },
      { value: ['email', 'phone'], dataType: 'contact', objectKey: 'demographic' },
      { value: ['updatedAt', 'name'], dataType: 'updated', objectKey: 'updatedBy', navigation: true },
      { value: '', actionButton: true },
    ],
    tableDatas: [],
    actionItems: [
    ]
  };
  tableHeadClass: string = 'bg-gray-light text-gray-dark'
  notFoundMessage = NODATA_MESSAGES.NO_ADMINISTRATORS;

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private nightWebUtil: NightwebUtil,
    private adminService: AdminService,
    private modalService: MdbModalService,
    private toasterService: ToasterService,
    private popconfirmService: MdbPopconfirmService,
    private organizationService: OrganizationService
  ) {
    this.setAdministratorForm();
    if (this.isWriteAccessible) {
      this.tableConfig.actionItems?.unshift(
        { name: 'Edit', eventName: 'editModule' },
        { name: 'Delete', icon: 'trash', eventName: 'deleteModule' }
      );
    }
  }

  ngOnInit(): void {
    // this.getAllAdministrators();
    this.loading = true;
    forkJoin([
      this.adminService.getAllAdministrators(),
      this.roleService.getAllRoles(),
      this.organizationService.getAllOrganizations()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [admins, roles, organizations] = response;
          this.administrators = admins.data.rows;
          this.roles = roles.data.rows;
          this.organizations = organizations.data.rows;
          this.tableConfig.tableDatas = this.administrators;
          this.mapRoles();
        },
        error: (e) => { }
      })
  }

  setAdministratorForm() {
    this.administratorForm = this.formBuilder.group({
      keyword: [''],
      limit: [NIGHTWEB_APP_CONSTANT.defaultLimit],
      offset: []
    });
  }

  mapRoles() {
    this.administrators = this.administrators.map((admin: any) => {
      if (admin?.organizations?.length) {
        const organization = this.organizations.find(org => org?.id === admin?.organizations[0]?.organizationId);
        admin.displayOrganization = organization?.displayName ?? '';
      }
      return admin;
    });
    this.tableConfig.tableDatas = this.tableConfig.tableDatas.map((admin: any) => {
      if (!admin.superRoleId) {
        admin.organizations = admin.organizations?.map((org: any) => {
          const role = this.roles.find(x => x.id === org.roleId);
          if (role) {
            return {
              ...org,
              roleName: role.name
            }
          } else {
            return { ...org };
          }
        });
        return {
          ...admin,
          updatedBy: {
            ...admin.updatedBy,
            updatedAt: admin.updatedAt
          }
        };
      } else {
        return {
          ...admin,
          updatedBy: {
            ...admin.updatedBy,
            updatedAt: admin.updatedAt
          },
          roleName: this.roles.find(x => x.id === admin.superRoleId)?.name
        };
      }
    });
  }

  getAllAdministrators() {
    this.loading = true;
    this.tableConfig.tableDatas = [];
    this.adminService.getAllAdministrators(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.administrators = response.data.rows;
          this.tableConfig.tableDatas = this.administrators;
          this.mapRoles();
        },
        error: (e) => {
        }
      })
  }

  openAddAdministratorModal(user?: any) {
    const config = this.nightWebUtil.shallowCopy(this.config);
    config.data.title = user ? 'Edit' : 'Add';
    config.modalClass = 'modal-dialog-scrollable modal-md';
    config.data.formData = user;
    this.administratorAddEditModalComponent = this.modalService.open(AddEditAdministratorModalComponent, config);
    this.administratorAddEditModalComponent.onClose.subscribe((data: any) => {
      if (data) {
        this.getAllAdministrators();
      }
    });
  }

  deleteAdmin(event: Event, user: any) {
    const target = event.target as HTMLElement;
    const config = this.nightWebUtil.shallowCopy(this.confirmPopConfig);
    config.data.confirmButtonLabel = 'Delete';
    config.data.title = `Delete`;
    config.data.body = MESSAGE_CONSTANTS.DELETE_CONFIRMATION;
    this.popconfirmRef = this.popconfirmService.open(PopConfirmComponent, target, config);
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.adminService.delete(user.id)
        .subscribe({
          next: (response) => {
            this.administrators.splice(this.administrators.findIndex(x => x.id == user.id), 1);
            this.tableConfig.tableDatas = this.administrators;
            this.mapRoles();
            this.toasterService.show("Success", MESSAGE_CONSTANTS.ADMIN_REMOVED);
          },
          error: (e) => {
          }
        })
    });
  }

  receiveEvent({ action, event, object }: any) {
    if (action === 'edit') {
      this.openAddAdministratorModal(object);
    } else if (action === 'delete') {
      this.deleteAdmin(event, object);
    }
  }

  receiveSelectedValue(event: any) {
    if (event) {
      this.administratorForm.patchValue({
        [event.controlName]: event.value
      });
      if (event.controlName === 'limit') {
        this.tableConfig.tableDatas = [];
        this.filter = this.nightWebUtil.getFilterParams(this.administratorForm.value)
        this.getAllAdministrators();
      }
    }
  }

  receiveSearchKeyword(keyword: any) {
    this.searchKeyword = keyword;
    this.administratorForm.patchValue({ keyword });
    this.filter = this.nightWebUtil.getFilterParams(this.administratorForm.value)
    this.getAllAdministrators();
  }

}
