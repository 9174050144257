import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, DataService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import { MENU } from 'src/app/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedInUser!: any;

  subscription!: Subscription;
  header:any[] = [];

  menus = MENU;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private nightwebUtil: NightwebUtil,
    private router: Router
  ) {
    this.loggedInUser = this.nightwebUtil.decodeToken(this.nightwebUtil.getLoggedInUser());
    this.dataService.getData().subscribe(
      (data) => {
        if (data?.key === 'update-profile') {
          this.loggedInUser = data?.user;
        }
      }
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.header = [];
        this.menus.forEach((route: any)=>{
          if (event.url.includes(route.route)) {
            this.header.push(route);
            const url = event.url?.split('/')?.filter(x=>x);
            if(url?.[1] && route.children){
              this.header.push(route.children);
            }
          }
        });
      }
    });
   }

  ngOnInit(): void {
  }
  logout() {
    this.authService.logout();
  }

}
