<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{ title }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="configurationForm" autocomplete="off">
        <div *ngIf="loading && !configurations.length">
            <app-loader [loader]="loading"></app-loader>
        </div>
        <div formArrayName="configurations">
            <div class="row mb-3" *ngFor="let config of configurations.controls; let i = index" [formGroupName]="i"
                [ngSwitch]="config.get('type')?.value">
                <div class="col-6">
                    <div class="d-flex flex-column">
                        <p class="mb-0 fw-bold">{{config.get('title')?.value}}</p>
                        <span class="fs-1">{{config.get('description')?.value}}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngSwitchCase="inputType.BOOLEAN">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" [id]="config.get('code')?.value"
                                formControlName="value">
                        </div>
                    </div>
                    <div *ngSwitchCase="inputType.UNIT">
                        <ng-container [ngSwitch]="config.get('metadata')?.value?.unitType?.type">
                            <div *ngSwitchCase="unitType.TIME">
                                <div class="d-flex flex-column">
                                    <input class="form-control" type="text" [id]="config.get('code')?.value"
                                        formControlName="value" mask="0*.0*" suffix="s">
                                    <span *ngIf="config.get('metadata')?.value?.note" class="fs-1"><i-bs
                                            name="info-circle" height="0.75rem" width="0.75rem"></i-bs>
                                        {{config.get('metadata')?.value?.note}}</span>
                                </div>
                            </div>
                            <div class="input-group" *ngSwitchCase="unitType.STRING">
                                <input class="form-control" type="text" [id]="config.get('code')?.value"
                                    formControlName="value">
                            </div>
                        </ng-container>
                    </div>
                    <div *ngSwitchCase="inputType.STRING">
                        <div class="input-group">
                            <input class="form-control" type="text" [id]="config.get('code')?.value"
                                formControlName="value">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer pt-0 d-flex justify-content-between">
    <button type="button" class="btn btn-gray-light" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="linkDevice()" [disabled]="loading"><span class="spinner-grow spinner-grow-sm"
            role="status" aria-hidden="true" *ngIf="loading"></span>{{ confirmButtonLabel }}</button>
</div>