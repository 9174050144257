<div class="d-flex justify-content-between">
    <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
    <div class="d-flex">
        <app-select [form]="deviceForm" [control]="deviceForm.controls['limit']" [controlName]="'limit'"
            [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'"
            (emitSelectedValue)="receiveSelectedValue($event)" class="me-2"></app-select>
        <button type="button" class="btn btn-primary" (click)="openAddEditDeviceModal()" *ngIf="isWriteAccessible">
            <i-bs name="plus"></i-bs> Add Device
        </button>
    </div>
</div>

<div class="mt-3">
    <app-table [tableConfig]="tableConfig" [module]="'Check in module'" [loading]="loading"
        [notFoundMessage]="notFoundMessage" [tableHeadClass]="tableHeadClass" (sendEvent)="receiveEvent($event)">
    </app-table>
</div>