import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private http: HttpClientService
  ) { }

  getAllDevices(query?: any) {
    return this.http.get(API_CONSTANTS.DEVICE, query)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(deviceId: string) {
    return this.http.get(API_CONSTANTS.DEVICE + `/${deviceId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  create(device: any) {
    return this.http.post(API_CONSTANTS.DEVICE, device)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  update(device: any) {
    return this.http.put(API_CONSTANTS.DEVICE + `/${device.id}`, device)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(deviceId: string) {
    return this.http.remove(API_CONSTANTS.DEVICE + `/${deviceId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

}
