<div class="card mb-3">
    <div class="card-body">
        <div class="row w-100">
            <ng-container *ngIf="user; then isLoaded; else notLoaded"></ng-container>
            <ng-template #isLoaded>
                <div class="col-auto">
                    <span class="nav-link avatar p-0 bg-success text-center text-white fw-bold">
                        {{userInitials}}
                    </span>
                </div>
                <div class="col-5">
                    <div class="d-flex flex-column">
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Name
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">
                                    {{
                                    (user?.demographic?.firstName??'')
                                    + ' ' +
                                    (user?.demographic?.middleName??'')
                                    + ' ' +
                                    (user?.demographic?.lastName??'')
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Date of birth
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.demographic?.dob}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Email
                            </div>
                            <div class="col-8">
                                <span class="fw-bold text-nowrap">{{user?.demographic?.email}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Mobile
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.demographic?.phone | formatPhoneNumber}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Address
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.demographic?.address?.home?.addressLine1}}
                                    {{user?.demographic?.address?.home?.addressLine2}}</span><br />
                                <span class="fw-bold">{{
                                    user?.demographic?.address?.home?.city + ', ' +
                                    user?.demographic?.address?.home?.state +
                                    ' '
                                    +
                                    user?.demographic?.address?.home?.zip}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="d-flex flex-column">
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Status
                            </div>
                            <div class="col-8">
                                <span class="fw-bold badge rounded-pill bg-opacity-25" 
                                [ngClass]="{
                                    'bg-primary text-primary': user?.status?.toLowerCase() === userStatus.NEW,
                                    'bg-success-light text-success': user?.status?.toLowerCase() === userStatus.ACTIVATED,
                                    'bg-warning text-warning': user?.status?.toLowerCase() === userStatus.IN_PROGRESS
                                    }"
                                >{{user?.status}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Device Name
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.activeDevice?.info?.name}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Associated Phone
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.activeDevice?.info?.associatedPhone | formatPhoneNumber}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Model/Serial No.
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.activeDevice?.info?.modelNumber}}</span> /
                                <span class="fw-bold"> {{user?.activeDevice?.info?.serialNumber}}</span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                EIN
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">{{user?.activeDevice?.info?.eidNumber}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #notLoaded>
                <div class="col-auto">
                    <span class="">
                        <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                    </span>
                </div>
                <div class="col-5">
                    <div class="d-flex flex-column">
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Name
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Date of birth
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Email
                            </div>
                            <div class="col-8">
                                <span class="fw-bold text-nowrap">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Mobile
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-4 text-end">
                                Address
                            </div>
                            <div class="col-8">
                                <span class="fw-bold">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span><br />
                                <span class="fw-bold">
                                    <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="d-flex flex-column">
                        <div class="row gx-5 mb-1">
                            <div class="col-6 text-end">
                                Status
                            </div>
                            <div class="col-6">
                                <span class="fw-bold"><app-skeleton-loader
                                        [config]="skeletonTheme"></app-skeleton-loader></span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-6 text-end">
                                Device Name
                            </div>
                            <div class="col-6">
                                <span class="fw-bold"><app-skeleton-loader
                                        [config]="skeletonTheme"></app-skeleton-loader></span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-6 text-end">
                                Associated Phone
                            </div>
                            <div class="col-6">
                                <span class="fw-bold"><app-skeleton-loader
                                        [config]="skeletonTheme"></app-skeleton-loader></span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-6 text-end">
                                Model/Serial No.
                            </div>
                            <div class="col-6">
                                <span class="fw-bold"><app-skeleton-loader
                                        [config]="skeletonTheme"></app-skeleton-loader></span>
                            </div>
                        </div>
                        <div class="row gx-5 mb-1">
                            <div class="col-6 text-end">
                                EIN
                            </div>
                            <div class="col-6">
                                <span class="fw-bold"><app-skeleton-loader
                                        [config]="skeletonTheme"></app-skeleton-loader></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- <div class="row mb-3">
    <p class="fw-bold mb-0">Activities</p>
</div> -->

<div class="d-flex justify-content-end align-items-center mb-3">
    <form [formGroup]="userDetailForm" autocomplete="off">
        <!-- <app-search></app-search> -->
        <div class="row gx-3">
            <div class="col-4">
                <label for="From" class="form-label">Created From</label>
                <mdb-form-control>
                    <input mdbInput [mdbDatepicker]="createdFrom" type="text" class="form-control" id="createdFrom"
                        formControlName="createdFrom" />
                    <mdb-datepicker-toggle [mdbDatepicker]="createdFrom"></mdb-datepicker-toggle>
                    <mdb-datepicker [inline]="true" [format]="mdbDateFormat" [maxDate]="getMaxDate()" #createdFrom
                        (dateChanged)="getFiltered()"></mdb-datepicker>
                </mdb-form-control>

            </div>
            <div class="col-4">
                <label for="To" class="form-label">Created To</label>
                <mdb-form-control>
                    <input mdbInput [mdbDatepicker]="createdTo" type="text" class="form-control" id="createdTo"
                        formControlName="createdTo" />
                    <mdb-datepicker-toggle [mdbDatepicker]="createdTo"></mdb-datepicker-toggle>
                    <mdb-datepicker [inline]="true" [format]="mdbDateFormat"
                        [minDate]="this.userDetailForm.controls['createdFrom'].value && getMinDate()"
                        [maxDate]="getMaxDate()" #createdTo (dateChanged)="getFiltered()"></mdb-datepicker>
                </mdb-form-control>
            </div>
            <div class="col-4 d-flex flex-column-reverse">
                <app-select [form]="userDetailForm" [control]="userDetailForm.controls['limit']" [controlName]="'limit'"
                    [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'"
                    (emitSelectedValue)="receiveSelectedValue($event)"></app-select>
            </div>
        </div>
    </form>
</div>

<app-table [tableConfig]="tableConfig" [module]="'Check in module'" [loading]="loading"
    [notFoundMessage]="notFoundMessage" (sendEvent)="receiveEvent($event)">
</app-table>