import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MdbAutocompleteSelectedEvent } from 'mdb-angular-ui-kit/autocomplete';
import { map, Observable, startWith, Subject, tap } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() data: any[] = [];
  @Input() control: any = '';
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = 'Search';
  @Input() filterKeys: any[] = [];

  @Output() emitSelectedValue = new EventEmitter<any>();

  searchText = new Subject<string>();
  results!: Observable<any[]>;
  notFound = false;

  constructor() {
    this.results = this.searchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value)),
      tap((results: string[]) =>
        this.notFound = results.length === 0
      )
    );
  }

  ngOnInit(): void {
  }
  
  getValue(option: any) {
    return option[this.value];
  }

  getOption(option: any) {
    return option[this.name];
  }

  filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    var result = this.data.filter((item: any) =>
      this.filterKeys.some((key) => {
        return item[key]?.toLowerCase().includes(filterValue);
      })
    );
    return result;
  }

  displayValue(option: any): string {
    return option['value'];
  }

  handleChange({ option: { value } }: MdbAutocompleteSelectedEvent) {
    this.emitSelectedValue.emit(value[this.value]);
  }
}
