export const USER_STATUS = {
    NEW: 'new',
    ACTIVATED: 'activated',
    IN_PROGRESS: 'in progress'
}

export const END_USER_STATUS = [
    {
        "name": "New",
        "value": "New",
    },
    {
        "name": "Activated",
        "value": "Activated",
    },
    {
        "name": "In Progress",
        "value": "In progress",
    }
];