<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{
        title + ' User'
        }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="userForm" autocomplete="off">
        <div formGroupName="demographic">
            <div class="row gx-3 mb-2">
                <div class="col-4">
                    <label for="firstName" class="form-label">First Name</label>
                    <app-input [name]="'firstName'" [type]="'text'" [control]="demographic.controls['firstName']"
                        [submitted]="submitted" [required]="true">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="middleName" class="form-label">Middle Name</label>
                    <app-input [name]="'middleName'" [type]="'text'" [control]="demographic.controls['middleName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="lastName" class="form-label">Family Name</label>
                    <app-input [name]="'lastName'" [type]="'text'" [control]="demographic.controls['lastName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
            </div>
            <div class="row gx-3 mb-2">
                <div class="col-8">
                    <label for="email" class="form-label">Email</label>
                    <app-input [name]="'email'" [type]="'text'" [control]="demographic.controls['email']"
                        [submitted]="submitted" [required]="true">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="gender" class="form-label">Gender</label>
                    <app-select [form]="userForm" [control]="demographic.controls['gender']" [controlName]="'gender'"
                        [filter]="false" [submitted]="submitted" [data]="genderList" class="mb-2"></app-select>
                </div>
            </div>

            <div class="row gx-3">
                <div class="col-6">
                    <label for="dob" class="form-label">Date of Birth</label>
                    <mdb-form-control>
                        <input mdbInput [mdbDatepicker]="dob" type="text" class="form-control" id="dob"
                            formControlName="dob" />
                        <mdb-datepicker-toggle [mdbDatepicker]="dob"></mdb-datepicker-toggle>
                        <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="maxDate" #dob></mdb-datepicker>
                    </mdb-form-control>
                </div>
                <div class="col-6">
                    <div class="md-form mb-2">
                        <label for="phone" class="form-label">Personal Phone </label>
                        <app-input [name]="'phone'" [type]="'text'" [control]="demographic.controls['phone']"
                            [mask]="phoneMask" [submitted]="submitted" [required]="true">
                        </app-input>
                    </div>
                </div>
            </div>
            <div formGroupName="address" class="mb-2">
                <div formGroupName="home">
                    <label for="addressLine1" class="form-label">Address</label>
                    <div class="row gx-3 mb-2">
                        <div class="col-8">
                            <app-input [name]="'addressLine1'" [type]="'text'" [control]="home.controls['addressLine1']"
                                [submitted]="submitted" [required]="true" [placeholder]="'Address Line 1'">
                            </app-input>
                        </div>
                        <div class="col-4">
                            <app-input [name]="'addressLine2'" [type]="'text'" [control]="home.controls['addressLine2']"
                                [submitted]="submitted" [placeholder]="'Address Line 2'">
                            </app-input>
                        </div>
                    </div>
                    <div class="row gx-3">
                        <div class="col-4">
                            <app-input [name]="'city'" [type]="'text'" [control]="home.controls['city']"
                                [submitted]="submitted" [required]="true" [placeholder]="'City'">
                            </app-input>
                        </div>
                        <div class="col-4">
                            <app-autocomplete [form]="userForm"
                                [control]="userForm.controls['demographic'].get('address')?.get('home')?.get('state')"
                                [data]="states" [name]="'label'" [value]="'value'" [filterKeys]="['value']"
                                [placeholder]="'State'" (emitSelectedValue)="receiveAutocompleteValue($event)">
                            </app-autocomplete>
                        </div>
                        <div class="col-4">
                            <app-input [name]="'zip'" [type]="'text'" [control]="home.controls['zip']" [mask]="'0*'"
                                [maxLength]="5" [submitted]="submitted" [required]="true" [placeholder]="'Zip'">
                            </app-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-form">
                <label for="organizationId" class="form-label">Organization</label>
                <app-select [form]="userForm" [control]="userForm.controls['organizationId']"
                    [controlName]="'organizationId'" [filter]="false" [submitted]="submitted" [data]="organizationsList"
                    [disabled]="role === 'ADVOCATE'" [name]="'displayName'" [value]="'id'"
                    (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2"></app-select>
            </div>

            <div class="md-form">
                <label for="advocateId" class="form-label">Advocate</label>
                <app-select [form]="userForm" [control]="userForm.controls['advocateId']" [controlName]="'advocateId'"
                    [filter]="false" [clearButton]="role !== 'ADVOCATE'" [submitted]="submitted" [data]="advocateList"
                    [disabled]="role === 'ADVOCATE'" [name]="'displayName'" [value]="'id'"
                    (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2"></app-select>
            </div>

            <div class="row gx-3">
                <div class="col-6">
                    <label for="safeWord" class="form-label">Safe Word</label>
                    <app-input [name]="'safeWord'" [type]="'text'" [control]="userForm.controls['safeWord']"
                        [submitted]="submitted" [required]="false">
                    </app-input>
                </div>

                <div class="col-6">
                    <label for="accountNumber" class="form-label">Account Number</label>
                    <app-input [name]="'accountNumber'" [type]="'text'" [control]="userForm.controls['accountNumber']"
                        [submitted]="submitted" [required]="false">
                    </app-input>
                </div>
            </div>


        </div>
    </form>
</div>

<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="userForm.invalid || loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        {{formData ? 'Update': 'Save'}}</button>
</div>