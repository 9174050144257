<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <!-- Container wrapper -->
    <div class="container-fluid justify-content-between">
        
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb ps-1">
                <li *ngFor="let head of header;first as isFirst" class="breadcrumb-item">
                    <a [routerLink]="(isFirst && header.length>1) ? [head.route]:[]" [class.active]="isFirst && header.length>1">
                        {{head.label}}
                    </a>
                </li>
            </ol>
        </nav>

        <ul class="navbar-nav mb-2 mb-lg-0">

            <li mdbDropdown class="nav-item dropdown">
                <a class="nav-link dropdown-toggle d-flex align-items-center gap-2 py-0" id="navbarDropdownMenuLink" role="button" mdbDropdownToggle
                    aria-expanded="false">
                    <span id="navbarDropdownMenuLink" class=" avatar bg-info text-center text-white">    
                       <i-bs name="person-fill"></i-bs>       
                    </span>
                    <span class="d-flex flex-column align-items-start">
                        <p class="mb-0">{{loggedInUser?.name}} </p>
                    <!-- <i-bs name="chevron-down"></i-bs> -->
                        <span
                    class="px-1 me-1 badge bg-info text-white">{{loggedInUser?.organization?.role?.name}}</span>
                    
                    </span>
                    
                </a>
                
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end border-0"
                    aria-labelledby="navbarDropdownMenuLink">
                    <li class="dropdown-header"  *ngIf="loggedInUser?.organization?.role?.code !== 'SUPER_ADMIN'"> 
                        <span class="px-1 badge  bg-primary">{{loggedInUser?.organization?.name}}</span>
                    </li>
                    <li  *ngIf="loggedInUser?.organization?.role?.code !== 'SUPER_ADMIN'"><hr class="dropdown-divider" /></li>
                    <li>
                        <a class="dropdown-item" role="button" [routerLink]="['/profile']"> My Profile</a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logout()" role="button">Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>