<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{
        title + ' Device'
        }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="deviceForm" autocomplete="off">
        <div class="md-form mb-2">
            <label for="name" class="form-label">Device Name</label>
            <app-input [name]="'name'" [type]="'text'" [control]="deviceForm.controls['name']" [submitted]="submitted">
            </app-input>
        </div>
        <div class="row gx-3 mb-2">
            <div class="col-6">
                <label for="modelNumber" class="form-label">Model Number</label>
                <app-input [name]="'modelNumber'" [type]="'text'" [control]="deviceForm.controls['modelNumber']"
                    [submitted]="submitted">
                </app-input>
            </div>
            <div class="col-6">
                <label for="serialNumber" class="form-label">Serial Number</label>
                <app-input [name]="'serialNumber'" [type]="'text'" [control]="deviceForm.controls['serialNumber']"
                    [submitted]="submitted">
                </app-input>
            </div>
        </div>
        <div class="md-form mb-2">
            <label for="eidNumber" class="form-label">EIN</label>
            <app-input [name]="'eidNumber'" [type]="'text'" [control]="deviceForm.controls['eidNumber']"
                [submitted]="submitted">
            </app-input>
        </div>
        <div class="md-form">
            <label for="phoneNumber" class="form-label">Device Phone</label>
            <app-input [name]="'associatedPhone'" [type]="'text'" [control]="deviceForm.controls['associatedPhone']"
                [mask]="phoneMask" [submitted]="submitted">
            </app-input>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="deviceForm.invalid || loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        {{formData ? 'Update': 'Save'}}</button>
</div>