<!-- Sidenav -->
<mdb-sidenav-layout>
    <mdb-sidenav 
        #sidenav="mdbSidenav" 
        [slim]="true" 
        position="fixed"
        [slimWidth]="64" 
        [width]="260" 
        [slimCollapsed]="false" 
        [hidden]="false"
        [accordion]="true"
        mode="side" 
        scrollContainer="#scroll-container"
        sidenavBreakpoint="768">
        <a role="button" mdbripple="" ripplecolor="primary"
            class="d-flex justify-content-start align-items-center brand position-relative" [ngClass]="sidenav.slimCollapsed ? 'p-3 collapse' :'p-2'">
            <!-- <h5 class="fw-bold mb-0">NEXION</h5> -->
            <img src="../../../../assets/img/png/NexionSolutionslogo-Dashboard.png" class="logo-full">
            <img src="../../../../assets/img/png/nexion-icon-logo.png" class="logo-icon" alt="">
            <button [ngStyle]="{'right': sidenav.slimCollapsed ? '-15px' :'-15px' }" #sidenavToggle
                (click)="sidenav.toggleSlim()" class="navbar-toggler bg-white btn-floating d-block shadow btn-sm position-absolute"
                type="button" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>
        </a>
        <app-sidebar class="app-sidenav overflow-auto"></app-sidebar>
    </mdb-sidenav>
    <mdb-sidenav-content #sidenavContent>
        <header class="sticky-top">
            <!-- <button [ngStyle]="{'left': sidenav.slimCollapsed ? '-15px' :'-15px' }" #sidenavToggle
                (click)="sidenav.toggleSlim()" class="navbar-toggler btn-floating d-block shadow sticky-top btn-sm"
                type="button" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button> -->
            <app-header></app-header>
        </header>
        <div class="container-fluid position-relative mt-3">
            <router-outlet></router-outlet>
        </div>
    </mdb-sidenav-content>
</mdb-sidenav-layout>