<div class="d-flex justify-content-between">
    <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
    <div class="d-flex justify-content-end ms-5 w-50">
        <app-select [form]="activityLogForm" [control]="activityLogForm.controls['logModule']"
            [controlName]="'logModule'" [clearButton]="true" [filter]="false" [submitted]="submitted" [data]="modules"
            [placeholder]="'Select Module'" (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline mx-2"></app-select>
        <app-select [form]="activityLogForm" [control]="activityLogForm.controls['logType']" [controlName]="'logType'"
            [clearButton]="true" [filter]="false" [submitted]="submitted" [data]="logTypes"
            [placeholder]="'Select Log Type'" (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline me-2"></app-select>
        <app-select [form]="activityLogForm" [control]="activityLogForm.controls['limit']" [controlName]="'limit'"
            [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'"
            (emitSelectedValue)="receiveSelectedValue($event)" class="select-outline"></app-select>
    </div>
</div>

<div class="mt-3">
    <app-table [tableConfig]="tableConfig" [module]="'Check in module'" [loading]="loading"
        [notFoundMessage]="notFoundMessage" [tableHeadClass]="tableHeadClass" (sendEvent)="receiveEvent($event)">
    </app-table>
</div>