<div [formGroup]="form">
    <mdb-form-control>
        <input mdbInput [ngModel]="(searchText | async) ?? control?.value" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="searchText.next($event)" [mdbAutocomplete]="autocomplete" type="text"
            id="autocomplete" class="form-control" [placeholder]="placeholder"/>
    </mdb-form-control>
    <mdb-autocomplete #autocomplete="mdbAutocomplete" [displayValue]="displayValue" (selected)="handleChange($event)">
        <mdb-option *ngFor="let option of results | async" [value]="option">
            {{
                getOption(option)
            }}
        </mdb-option>
        <div *ngIf="notFound" class="autocomplete-no-results">No results found</div>
    </mdb-autocomplete>
</div>