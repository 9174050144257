<form [formGroup]="changePasswordForm" autocomplete="off">
    <div class="d-flex flex-column w-50">
        <div class="md-form mb-2">
            <label for="oldPassword" class="form-label">Current Password</label>
            <div class="input-group flex-nowrap me-3"
                [class.is-invalid]="changePasswordForm.controls['oldPassword'].dirty && changePasswordForm.get('oldPassword')?.invalid">
                <div class="input-group">
                    <input [type]="showLoginPassword ? 'text' : 'password'" class="form-control px-1 bg-gray-light"
                        id="oldPassword" formControlName="oldPassword" />
                    <span (click)="showLoginPassword = !showLoginPassword"
                        class="input-group-text px-1 cursor-pointer bg-gray-light">
                        <i-bs class="mx-2" [name]="showLoginPassword ? 'eye' : 'eye-slash'"></i-bs>
                    </span>
                </div>
            </div>
        </div>
        <div class="md-form mb-2">
            <label for="newPassword" class="form-label">New Password</label>
            <div class="input-group flex-nowrap me-3"
                [class.is-invalid]="changePasswordForm.controls['newPassword'].dirty && changePasswordForm.get('newPassword')?.invalid">
                <div class="input-group">
                    <input [type]="showNewPassword ? 'text' : 'password'" class="form-control px-1 bg-gray-light"
                        id="newPassword" formControlName="newPassword" />
                    <span (click)="showNewPassword = !showNewPassword"
                        class="input-group-text px-1 cursor-pointer bg-gray-light">
                        <i-bs class="mx-2" [name]="showNewPassword ? 'eye' : 'eye-slash'"></i-bs>
                    </span>
                </div>
            </div>
        </div>
        <div class="md-form mb-2">
            <label for="confirmNewPassword" class="form-label">Confirm Password</label>
            <div class="input-group flex-nowrap me-3"
                [class.is-invalid]="changePasswordForm.controls['confirmNewPassword'].dirty && changePasswordForm.get('confirmNewPassword')?.invalid">
                <div class="input-group">
                    <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control px-1 bg-gray-light"
                        id="confirmNewPassword" formControlName="confirmNewPassword" />
                    <span (click)="showConfirmPassword = !showConfirmPassword"
                        class="input-group-text px-1 cursor-pointer bg-gray-light">
                        <i-bs class="mx-2" [name]="showConfirmPassword ? 'eye' : 'eye-slash'"></i-bs>
                    </span>
                </div>
            </div>
        </div>

        <div class="col col-md-8">
            <span class="fs-1">Password must include</span>
            <ul class="fa-ul text-left">
                <li class="mb-1 fs-1">
                    <span class="fa-li">
                        <i-bs [name]="
                        (changePasswordForm.value.newPassword &&
                        changePasswordForm.controls['newPassword'].dirty &&
                        changePasswordForm.controls['newPassword'].valid ? 
                        'check-circle' : (
                            (changePasswordForm.value.newPassword &&
                            changePasswordForm.controls['newPassword'].dirty && 
                            changePasswordForm.controls['newPassword'].invalid) ?
                            'x-circle' : 'exclamation-circle')
                        )" class="{{ 
                            (changePasswordForm.value.newPassword && 
                            changePasswordForm.controls['newPassword'].dirty && 
                            changePasswordForm.controls['newPassword'].valid) ?
                            'text-success' : (
                                (changePasswordForm.value.newPassword &&
                                changePasswordForm.controls['newPassword'].dirty && 
                                changePasswordForm.controls['newPassword'].invalid) ?
                                'text-danger' : 'text-gray-dark'
                            )
                        }}">
                        </i-bs>
                    </span>
                    <small class="{{ 
                    (changePasswordForm.value.newPassword && 
                    changePasswordForm.controls['newPassword'].dirty && 
                    changePasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (changePasswordForm.value.newPassword && 
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                        At least 8 characters
                    </small>
                </li>
                <li class="mb-1 fs-1">
                    <span class="fa-li">
                        <i-bs [name]="
                    (changePasswordForm.value.newPassword &&
                    changePasswordForm.controls['newPassword'].dirty &&
                    changePasswordForm.controls['newPassword'].valid ? 
                    'check-circle' : (
                        (changePasswordForm.value.newPassword &&
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle')
                    )" class="{{ 
                        (changePasswordForm.value.newPassword && 
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].valid) ?
                        'text-success' : (
                            (changePasswordForm.value.newPassword &&
                            changePasswordForm.controls['newPassword'].dirty && 
                            changePasswordForm.controls['newPassword'].invalid) ?
                            'text-danger' : 'text-gray-dark'
                        )
                    }}">
                        </i-bs>
                    </span>
                    <small class="{{ 
                    (changePasswordForm.value.newPassword && 
                    changePasswordForm.controls['newPassword'].dirty && 
                    changePasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (changePasswordForm.value.newPassword && 
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                        At least one Uppercase
                    </small>
                </li>
                <li class="mb-1 fs-1">
                    <span class="fa-li">
                        <i-bs [name]="
                    (changePasswordForm.value.newPassword &&
                    changePasswordForm.controls['newPassword'].dirty && 
                    changePasswordForm.controls['newPassword'].valid) ?
                    'check-circle' : (
                        (changePasswordForm.value.newPassword &&
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle'
                    )
                    " class="{{ 
                    (changePasswordForm.value.newPassword && 
                    changePasswordForm.controls['newPassword'].dirty && 
                    changePasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (changePasswordForm.value.newPassword &&
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                }}">
                        </i-bs>
                    </span>
                    <small class="{{ 
                    (changePasswordForm.value.newPassword && 
                    changePasswordForm.controls['newPassword'].dirty && 
                    changePasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (changePasswordForm.value.newPassword &&
                        changePasswordForm.controls['newPassword'].dirty && 
                        changePasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                        At least one special character i.e #@! and a number
                    </small>
                </li>
                <li class="mb-1 fs-1">
                    <span class="fa-li">
                        <i-bs [name]="
                    (changePasswordForm.value.confirmNewPassword &&
                    changePasswordForm.controls['confirmNewPassword'].dirty && 
                    changePasswordForm.controls['confirmNewPassword'].valid) ?
                    'check-circle' : (
                        (changePasswordForm.value.confirmNewPassword &&
                        changePasswordForm.controls['confirmNewPassword'].dirty && 
                        changePasswordForm.controls['confirmNewPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle'
                    )
                    " class="{{
                    (changePasswordForm.value.confirmNewPassword && 
                    changePasswordForm.controls['confirmNewPassword'].dirty && 
                    changePasswordForm.controls['confirmNewPassword'].valid) ?
                    'text-success' : (
                        (
                        changePasswordForm.value.confirmNewPassword &&
                        changePasswordForm.controls['confirmNewPassword'].dirty && 
                        changePasswordForm.controls['confirmNewPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                }}">
                        </i-bs>
                    </span>
                    <small class="{{ 
                    (changePasswordForm.value.confirmNewPassword && 
                    changePasswordForm.controls['confirmNewPassword'].dirty && 
                    changePasswordForm.controls['confirmNewPassword'].valid) ?
                    'text-success' : (
                        (changePasswordForm.value.confirmNewPassword &&
                        changePasswordForm.controls['confirmNewPassword'].dirty && 
                        changePasswordForm.controls['confirmNewPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                        New Password and Confirm Password matched
                    </small>
                </li>
            </ul>
        </div>

        <div class="d-flex justify-content-end">
            <!-- <button type="button" class="btn btn-gray-lightest" (click)="cancel()">Discard Changes</button> -->
            <button type="submit" class="btn btn-primary" (click)="save()"
                [disabled]="changePasswordForm.invalid || loading">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                    *ngIf="loading"></span>Change
                Password</button>
        </div>
    </div>
</form>